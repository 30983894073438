<template>
    <div class="ubicacion">
        <!-- alerta CRUD -->
        <v-snackbar v-model="alerta" :color="colorAlerta" right top :timeout="timeout">
            {{ textoAlerta }}
            <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" @click="alerta = false">
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <!-- fin alerta CRUD -->
        <v-card>
            <v-tabs v-model="tab" align-with-title>
                <v-tabs-slider></v-tabs-slider>
                <v-tab>
                    Tipo de tratamiento
                </v-tab>
                <v-tab>
                    Tratamiento
                </v-tab>
            </v-tabs>
            <v-container>
                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <v-card>
                            <!-- modal tipo de tratamiento -->
                            <v-dialog v-model="modalTiposTratamientos" max-width="500px" persistent>
                                <v-card>
                                    <v-toolbar color="primary" dark>
                                        <v-card-title>
                                            <span class="text-h5">{{ tituloTipoTratamiento }}</span>
                                        </v-card-title>
                                    </v-toolbar>
                                    <v-card-text>
                                        <v-container>
                                            <v-form ref="frmTipo" v-model="validoTipoTratamiento" lazy-validation>
                                                <v-text-field v-model="editTipoTratamiento.nombre"
                                                    :rules="reglaNombreTipo" prepend-inner-icon="mdi-format-text"
                                                    label="Name" outlined clearable required>
                                                </v-text-field>
                                                <v-divider></v-divider>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn color="error" class="mr-4" @click="cancelarTipo">
                                                        Cancelar
                                                    </v-btn>
                                                    <v-btn :disabled="!validoTipoTratamiento" color="success"
                                                        class="mr-4" @click="guardarTipo">
                                                        Guardar
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-form>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                            </v-dialog>
                            <!-- fin modal tipo de tratamiento -->
                            <!-- modal eliminar tipo de tratamiento -->
                            <v-dialog v-model="modalEliminarTipo" max-width="550px">
                                <v-card>
                                    <v-card-title class="text-h6 font-weight-ligth text--secondary">
                                        <v-icon x-large left color="error">
                                            mdi-alert-octagon-outline
                                        </v-icon>
                                        ¿Estas seguro que deceas eliminar este campo?
                                    </v-card-title>
                                    <v-divider></v-divider>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="error" @click="cerrarEliminarTipo">
                                            Cancelar
                                        </v-btn>
                                        <v-btn color="success" @click="confirmarElimTipo">
                                            Aceptar
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <!-- fin modal eliminar tipo de tratamiento -->
                            <!-- tabla tipo de tratamiento -->
                            <tabla :header="headersTiposTratamientos" :body="tiposTratamientos"
                                tituloTabla="TIPOS DE TRATAMIENTOS" :accionAgregar="true" :exportar="true"
                                @recargar="listarTipo" @agregar="agregarTipo" @actualizar="editarTipo"
                                @eliminar="eliminarTipo" />
                            <!-- fin tabla tipo de tratamiento -->
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card>
                            <!-- modal tratamiento -->
                            <v-dialog v-model="modalTratamiento" max-width="500px" persistent>
                                <v-card>
                                    <v-card-title>
                                        <span class="text-h5">{{ tituloTratamiento }}</span>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-container>
                                            <v-form ref="frmTratamiento" v-model="validoTratamiento" lazy-validation>
                                                <v-text-field v-model="editTratamiento.nombre"
                                                    :rules="reglaNombreTratamientos"
                                                    prepend-inner-icon="mdi-format-text" label="Name" outlined clearable
                                                    required>
                                                </v-text-field>
                                                <v-autocomplete v-model="editTratamiento.tipoTratamiento"
                                                    :rules="reglaSlcTratamiento" :items="tiposTratamientos"
                                                    item-value="Uid" item-text="nombre"
                                                    prepend-inner-icon="mdi-account-tie-voice" label="Tipo" outlined
                                                    clearable required></v-autocomplete>
                                                <v-divider></v-divider>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn color="error" class="mr-4" @click="cancelarTratamiento">
                                                        Cancelar
                                                    </v-btn>
                                                    <v-btn :disabled="!validoTratamiento" color="success" class="mr-4"
                                                        @click="guardarTratamiento">
                                                        Guardar
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-form>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                            </v-dialog>
                            <!-- fin modal tratamiento -->
                            <!-- modal eliminar tipo de tratamiento -->
                            <v-dialog v-model="modalEliminarTratamiento" max-width="550px">
                                <v-card>
                                    <v-card-title class="text-h6 font-weight-ligth text--secondary">
                                        <v-icon x-large left color="error">
                                            mdi-alert-octagon-outline
                                        </v-icon>
                                        ¿Estas seguro que deceas eliminar este campo?
                                    </v-card-title>
                                    <v-divider></v-divider>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="error" @click="cerrarEliminarTratamientos">
                                            Cancelar
                                        </v-btn>
                                        <v-btn color="success" @click="confirmarElimTratamientos">
                                            Aceptar
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <!-- fin modal eliminar tipo de tratamiento -->
                            <!-- tabla tratamiento -->
                            <tabla :header="headersTratamiento" :body="tratamientos" tituloTabla="TRATAMIENTO"
                                :accionAgregar="true" :exportar="true" @recargar="listarTratamiento"
                                @agregar="agregarTratamiento" @actualizar="editarTratamientos"
                                @eliminar="eliminarTratamientos" />
                            <!-- fin tabla tratamiento -->
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-container>
        </v-card>
    </div>
</template>
<style>

</style>
<script>
    import {
        mapState,
        mapActions
    } from "vuex";
    import tabla from "../../components/view/Tabla";

    export default {
        name: "ubicacion",
        components: {
            tabla,
        },
        data: () => ({
            url_api: process.env.VUE_APP_URL_API,
            tab: null,

            alerta: false,
            textoAlerta: '',
            colorAlerta: '',
            timeout: 2000,
            // variables tipoTratamiento 
            UidTipo: null,
            buscarTiposTratamientos: '',
            validoTipoTratamiento: true,
            modalTiposTratamientos: false,
            modalEliminarTipo: false,
            headersTiposTratamientos: [{
                    text: '#',
                    value: 'numero'
                },
                {
                    text: 'Nombre / Tipo tratamiento',
                    align: 'start',
                    value: 'nombre',
                },
                {
                    text: 'Acciones',
                    value: 'acciones',
                    sortable: false
                },
            ],
            tiposTratamientos: [],
            reglaNombreTipo: [
                v => !!v || 'El nombre del tipoTratamiento es obligatorio.'
            ],
            editedTipoIndex: -1,
            editTipoTratamiento: {
                nombre: ''
            },
            edefaultTipoTratamiento: {
                nombre: ''
            },
            // fin variables tipoTratamiento

            //variables tratamiento
            UidTratamiento: null,
            buscarTratamiento: '',
            validoTratamiento: true,
            modalTratamiento: false,
            modalEliminarTratamiento: false,
            headersTratamiento: [{
                    text: '#',
                    value: 'numero'
                }, {
                    text: 'Nombre / Tratamiento',
                    align: 'start',
                    value: 'nombre',
                },
                {
                    text: 'Acciones',
                    value: 'acciones',
                    sortable: false
                },
            ],
            tratamientos: [],
            reglaNombreTratamientos: [
                v => !!v || 'El nombre del tratamiento es obligatorio.'
            ],
            reglaSlcTratamiento: [v => !!v || 'El tipoTratamiento es obligatorio'],
            editedTratamientoIndex: -1,
            editTratamiento: {
                nombre: '',
                tipoTratamiento: null,
            },
            defaultTratamiento: {
                nombre: '',
                tipoTratamiento: null,
            },
            // fin variables tratamiento
        }),

        computed: {
            ...mapState(["token"]),
            // titulo modal tipoTratamiento 
            tituloTipoTratamiento() {
                return this.editedTipoIndex === -1 ? 'Nuevo Tipo' : 'Editar Tipo'
            },
            // titulo modal tratamiento 
            tituloTratamiento() {
                return this.editedTratamientoIndex === -1 ? 'Nuevo Tratamiento' : 'Editar Tratamiento'
            },
        },

        watch: {
            ...mapActions(['cerrarSesion']),

            // modal tipoTratamiento
            modalTiposTratamientos(val) {
                val || this.cancelarTipo()
            },
            modalEliminarTipo(val) {
                val || this.cerrarEliminarTipo()
            },
            // fin modal tipoTratamiento
            // modal tratamiento
            modalTratamiento(val) {
                val || this.cancelarTratamiento()
            },
            modalEliminarTratamiento(val) {
                val || this.cerrarEliminarTratamientos()
            },
            // fin modal tratamiento
        },

        created() {
            // listar tiposTratamientos
            this.listarTipo()
            // listar tratamientos
            this.listarTratamiento()
        },

        methods: {
            // CRUD tiposTratamientos 
            async listarTipo() {
                try {
                    await fetch(`${this.url_api}/tipo_tratamiento`, {
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                this.tiposTratamientos = response.body
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
            },

            agregarTipo() {
                this.modalTiposTratamientos = true;
            },

            editarTipo(tipoTratamiento) {
                this.editedTipoIndex = this.tiposTratamientos.indexOf(tipoTratamiento)
                this.editTipoTratamiento = Object.assign({}, tipoTratamiento)
                this.modalTiposTratamientos = true
                this.UidTipo = tipoTratamiento.Uid;
            },

            eliminarTipo(tipoTratamiento) {
                this.editedTipoIndex = this.tiposTratamientos.indexOf(tipoTratamiento)
                this.editTipoTratamiento = Object.assign({}, tipoTratamiento)
                this.modalEliminarTipo = true
                this.UidTipo = tipoTratamiento.Uid;
            },

            async confirmarElimTipo() {
                try {
                    await fetch(`${this.url_api}/tipo_tratamiento/${this.UidTipo}`, {
                            method: 'DELETE',
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body == 'exito') {
                                this.alerta = false;
                                this.textoAlerta = 'Dato eliminado con Exito!';
                                this.colorAlerta = 'warning'
                                this.alerta = true;
                                this.tiposTratamientos.splice(this.editedTipoIndex, 1)
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            } else if (response.error) {
                                this.alerta = false;
                                this.textoAlerta = 'Rerror al eliminar!';
                                this.colorAlerta = 'error'
                                this.alerta = true;
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
                this.cerrarEliminarTipo()
            },

            cancelarTipo() {
                this.$refs.frmTipo.reset()
                this.$refs.frmTipo.resetValidation()
                this.modalTiposTratamientos = false
                this.$nextTick(() => {
                    this.editTipoTratamiento = Object.assign({}, this.edefaultTipoTratamiento)
                    this.editedTipoIndex = -1
                })
            },

            cerrarEliminarTipo() {
                this.modalEliminarTipo = false
                this.$nextTick(() => {
                    this.editTipoTratamiento = Object.assign({}, this.edefaultTipoTratamiento)
                    this.editedTipoIndex = -1
                })
            },
            error() {
                this.textoAlerta = 'Error';
                this.colorAlerta = 'error'
                this.alerta = true;
            },

            async guardarTipo() {
                if (this.$refs.frmTipo.validate()) {
                    if (this.editedTipoIndex > -1) {
                        try {
                            await fetch(`${this.url_api}/tipo_tratamiento/${this.UidTipo}`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "auth-token": this.token
                                    },
                                    body: JSON.stringify(this.editTipoTratamiento)
                                }).then(res => res.json())
                                .catch(error => console.error('Error:', error))
                                .then(response => {
                                    if (response.body == 'exito') {
                                        this.alerta = false;
                                        this.textoAlerta = 'Dato actualizado con Exito!';
                                        this.colorAlerta = 'primary'
                                        this.alerta = true;
                                        Object.assign(this.tiposTratamientos[this.editedTipoIndex], this
                                            .editTipoTratamiento)
                                    } else if (response.error == ('!token' || 'tokenNull')) {
                                        this.cerrarSesion();
                                    } else if (response.error) {
                                        this.alerta = false;
                                        this.textoAlerta = 'Error al actualizar!';
                                        this.colorAlerta = 'error'
                                        this.alerta = true;
                                    }

                                });
                        } catch (error) {
                            console.error('Error:', error);
                        }
                        this.UidTipo = null
                    } else {
                        try {
                            await fetch(`${this.url_api}/tipo_tratamiento`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "auth-token": this.token
                                    },
                                    body: JSON.stringify(this.editTipoTratamiento)
                                }).then(res => res.json())
                                .catch(error => console.error('Error:', error))
                                .then(response => {
                                    if (response.body == 'exito') {
                                        this.alerta = false;
                                        this.textoAlerta = 'Dato guardado con Exito!';
                                        this.colorAlerta = 'success'
                                        this.alerta = true;
                                        this.listarTipo();
                                    } else if (response.error == ('!token' || 'tokenNull')) {
                                        this.cerrarSesion();
                                    } else if (response.error) {
                                        this.alerta = false;
                                        this.textoAlerta = 'Error al guardar!';
                                        this.colorAlerta = 'error'
                                        this.alerta = true;
                                    }
                                });
                        } catch (error) {
                            console.error('Error:', error);
                        }
                    }
                    this.cancelarTipo()
                }
            },
            // fin CRUD tiposTratamientos

            // CRUD tratamientos 
            async listarTratamiento() {
                try {
                    await fetch(`${this.url_api}/tratamiento`, {
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                this.tratamientos = response.body
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
            },

            agregarTratamiento() {
                this.modalTratamiento = true;
            },

            editarTratamientos(tratamiento) {
                this.editedTratamientoIndex = this.tratamientos.indexOf(tratamiento)
                this.editTratamiento = Object.assign({}, tratamiento)
                this.modalTratamiento = true
                this.UidTratamiento = tratamiento.Uid;
                this.tiposTratamientos.forEach(element => {
                    if (element.Uid == tratamiento.tipo) {
                        this.editTratamiento.tipoTratamiento = element.Uid;
                    }
                });
            },

            eliminarTratamientos(tratamiento) {
                this.editedTratamientoIndex = this.tratamientos.indexOf(tratamiento)
                this.editTratamiento = Object.assign({}, tratamiento)
                this.modalEliminarTratamiento = true
                this.UidTratamiento = tratamiento.Uid;
            },

            async confirmarElimTratamientos() {
                try {
                    await fetch(`${this.url_api}/tratamiento/${this.UidTratamiento}`, {
                            method: 'DELETE',
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body == 'exito') {
                                this.alerta = false;
                                this.textoAlerta = 'Dato eliminado con Exito!';
                                this.colorAlerta = 'warning'
                                this.alerta = true;
                                this.tratamientos.splice(this.editedTratamientoIndex, 1)
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            } else if (response.error) {
                                this.alerta = false;
                                this.textoAlerta = 'Rerror al eliminar!';
                                this.colorAlerta = 'error'
                                this.alerta = true;
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
                this.cerrarEliminarTratamientos();
            },

            cancelarTratamiento() {
                this.$refs.frmTratamiento.reset()
                this.$refs.frmTratamiento.resetValidation()
                this.modalTratamiento = false
                this.$nextTick(() => {
                    this.editTratamiento = Object.assign({}, this.defaultTratamiento)
                    this.editedTratamientoIndex = -1
                })
            },

            cerrarEliminarTratamientos() {
                this.modalEliminarTratamiento = false
                this.$nextTick(() => {
                    this.editTratamiento = Object.assign({}, this.defaultTratamiento)
                    this.editedTratamientoIndex = -1
                })
            },

            async guardarTratamiento() {
                if (this.$refs.frmTratamiento.validate()) {
                    if (this.editedTratamientoIndex > -1) {
                        try {
                            await fetch(`${this.url_api}/tratamiento/${this.UidTratamiento}`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "auth-token": this.token
                                    },
                                    body: JSON.stringify(this.editTratamiento)
                                }).then(res => res.json())
                                .catch(error => console.error('Error:', error))
                                .then(response => {
                                    if (response.body == 'exito') {
                                        this.alerta = false;
                                        this.textoAlerta = 'Dato actualizado con Exito!';
                                        this.colorAlerta = 'primary'
                                        this.alerta = true;
                                        Object.assign(this.tratamientos[this.editedTratamientoIndex], this
                                            .editTratamiento)
                                    } else if (response.error == ('!token' || 'tokenNull')) {
                                        this.cerrarSesion();
                                    } else if (response.error) {
                                        this.alerta = false;
                                        this.textoAlerta = 'Error al actualizar!';
                                        this.colorAlerta = 'error'
                                        this.alerta = true;
                                    }
                                });
                        } catch (error) {
                            console.error('Error:', error);
                        }
                        this.Uid = null
                    } else {
                        try {
                            await fetch(`${this.url_api}/tratamiento`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "auth-token": this.token
                                    },
                                    body: JSON.stringify(this.editTratamiento)
                                }).then(res => res.json())
                                .catch(error => console.error('Error:', error))
                                .then(response => {
                                    if (response.body == 'exito') {
                                        this.alerta = false;
                                        this.textoAlerta = 'Dato agregados con Exito!';
                                        this.colorAlerta = 'success'
                                        this.alerta = true;
                                        this.listarTratamiento();
                                    } else if (response.error == ('!token' || 'tokenNull')) {
                                        this.cerrarSesion();
                                    } else if (response.error) {
                                        this.alerta = false;
                                        this.textoAlerta = 'Error al agregar!';
                                        this.colorAlerta = 'error'
                                        this.alerta = true;
                                    }
                                });
                        } catch (error) {
                            console.error('Error:', error);
                        }
                    }
                    this.cancelarTratamiento();
                }
            },
            // fin CRUD tratamientos
        },
    }
</script>